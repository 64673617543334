import { render, staticRenderFns } from "./nx-data-tabs.vue?vue&type=template&id=163f91e2&scoped=true"
import script from "./nx-data-tabs.vue?vue&type=script&lang=js"
export * from "./nx-data-tabs.vue?vue&type=script&lang=js"
import style0 from "./nx-data-tabs.vue?vue&type=style&index=0&id=163f91e2&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "163f91e2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/crm_web_5uHk/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('163f91e2')) {
      api.createRecord('163f91e2', component.options)
    } else {
      api.reload('163f91e2', component.options)
    }
    module.hot.accept("./nx-data-tabs.vue?vue&type=template&id=163f91e2&scoped=true", function () {
      api.rerender('163f91e2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/nx-data-tabs/nx-data-tabs.vue"
export default component.exports