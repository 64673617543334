var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "data-tabs" },
    [
      _c(
        "el-row",
        { staticClass: "sewfEs" },
        _vm._l(_vm.data, function (item, index) {
          return _c(
            "el-col",
            {
              key: index,
              staticClass: "sewfw",
              style: _vm.style,
              attrs: { span: _vm.span },
            },
            [
              _c("img", {
                staticClass: "image",
                attrs: { src: item.imgUrl, alt: "" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "item" }, [
                _c("div", { staticClass: "item-header" }, [
                  _c("p", [_vm._v(_vm._s(item.title))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-body" }, [
                  _c("h2", [_vm._v(_vm._s(item.count))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item-footer" }, [
                  _c("p", [_vm._v(_vm._s(item.text))]),
                ]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "item-tip iconfont sewf",
                  class: item.key,
                }),
              ]),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }