/*
 * @name: 张梦琳
 * @test: test font
 * @Date: 2021-02-01 15:55:15
 * @FilePath: \web_bcrm\src\api\user_restPwd\Managesubordinates\department.js
 * @msg:
 * @param:
 * @return:
 */
import request from '@/utils/request';
//zml下级全部数据列表
//数据分析
export function MyLoweList(data) {
  return request({
    url: '/clientserver.php/MyLowe/structureStatistics',
    method: 'post',
    data: data
  });
}
//部门
export function getStructureListArr(data) {
  return request({
    url: '/clientserver.php/Common/getStructureListArr',
    method: 'post',
    data: data
  });
}

//数据分析
//来源数据分析zml
export function sourceIndex(data) {
  return request({
    url: '/clientserver.php/CustomerStatistics/sourceIndex',
    method: 'post',
    data: data
  });
}

//跟进数据--------全部数据zml
export function Followupanalysis(data) {
  return request({
    url: '/clientserver.php/CustomerLevelStatistics/index',
    method: 'post',
    data: data
  });
}
//数据分析 总数据查询
export function Totaldata(data) {
  return request({
    url: '/clientserver.php/CustomerStatistics/index',
    method: 'post',
    data: data
  });
}
// 客户分析zzy
//归属地
export function mobileProvinceIndex(data) {
  return request({
    url: '/clientserver.php/CustomerStatistics/mobileProvinceIndex',
    method: 'post',
    data: data
  });
}
export function CustomerIttnxlIndex() {
  return request({
    url: '/clientserver.php/CustomerStatistics/ittnxlIndex',
    method: 'post'
  });
}
export function ittnyxIndex() {
  return request({
    url: '/clientserver.php/CustomerStatistics/ittnyxIndex',
    method: 'post'
  });
}
export function ittnzyIndex() {
  return request({
    url: '/clientserver.php/CustomerStatistics/ittnzyIndex',
    method: 'post'
  });
}