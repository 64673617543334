//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {},
  name: 'nx-data-tabs',
  data: function data() {
    return {
      style: this.option.style,
      span: this.option.span,
      data: this.option.data || []
    };
  },
  props: {
    option: {
      type: Object,
      default: function _default() {}
    }
  }
};