var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container personalStatics" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "auto" } },
              [
                _c(
                  "el-row",
                  { staticStyle: { height: "32px" }, attrs: { gutter: 24 } },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              { staticStyle: { "padding-top": "0" } },
              [
                _c("nx-data-tabs", {
                  staticStyle: { margin: "10px 0px", "padding-left": "16%" },
                  attrs: { option: _vm.easyDataOption },
                }),
                _vm._v(" "),
                _c("div", {
                  style: { width: "100%", height: "300px" },
                  attrs: { id: "myChart" },
                }),
                _vm._v(" "),
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.tableData,
                        width: "100%",
                        border: "",
                        "header-cell-style": {
                          background: "#eef1f6",
                          color: "#606266",
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "来源地区",
                          width: "150",
                          align: "center",
                          prop: "mobile_province",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "num",
                          label: "客户量",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "总数据", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      attrs: { size: "small", align: "center" },
                                    },
                                    [_vm._v(_vm._s(scope.row.ratio) + "%")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1099195887
                        ),
                      }),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }